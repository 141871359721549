import React from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
// import styles from './MainLayout.module.css';
// import './styles.css';

type MainLayoutProps = {
	children?: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
	return (
		<div className="grid min-h-screen grid-cols-1 grid-rows-[auto_1fr_auto]">
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
};
