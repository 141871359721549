import {
	UseQueryOptions,
	DefaultOptions,
	QueryClient,
} from '@tanstack/react-query';
import { Promisable } from 'type-fest';

const queryConfig: DefaultOptions = {
	queries: {
		throwOnError: true,
		refetchOnWindowFocus: false,
		retry: false,
	},
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: never) => never> =
	Promisable<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends (...args: never) => never> = Omit<
	UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
	'queryKey' | 'queryFn'
>;
