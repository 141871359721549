import React from 'react';
import { AppProvider } from './providers/app';
import { AppRoutes } from './routes';
import '@fortawesome/fontawesome-pro/css/all.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';

const App = () => {
	return (
		<AppProvider>
			<AppRoutes />
		</AppProvider>
	);
};

export default App;
