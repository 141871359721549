import React from 'react';

export const footerContent = {
	common: (
		<div className="flex flex-col gap-2 border border-gardian-darkgray p-8">
			<p className="text-sm">
				GARDIAN is an online portal that provides access to agricultural
				research data produced by{' '}
				<a
					className="font-semibold"
					target={'_blank'}
					rel="noreferrer"
					href={'https://www.cgiar.org/'}
				>
					{' '}
					CGIAR{' '}
				</a>
				Centers and their implementing partners. It is administered by
				the{' '}
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://bigdata.cgiar.org/'}
					rel="noreferrer"
				>
					{' '}
					CGIAR Platform for Big Data in Agriculture{' '}
				</a>
				(which is co-led by the International Center for Tropical
				Agriculture and the International Food Policy Research
				Institute). Access to GARDIAN is provided as a public good in
				accordance with{' '}
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://www.cgiar.org/how-we-work/strategy/'}
					rel="noreferrer"
				>
					{' '}
					CGIAR’s mission{' '}
				</a>{' '}
				to reduce poverty, improve food and nutrition security, and
				improve natural resources and ecosystem services.
			</p>
			<p className="text-sm">
				The{' '}
				<a
					className="font-semibold"
					target={'_blank'}
					href={'https://bigdata.cgiar.org/'}
					rel="noreferrer"
				>
					{' '}
					CGIAR Platform for Big Data in Agriculture{' '}
				</a>{' '}
				is committed to user privacy and this notice explains how we
				manage personally identifiable information collected from you
				when you use GARDIAN. It also explains our practices regarding
				personally identifiable information of third parties which may
				be accessible or discoverable through GARDIAN.
			</p>
			<p className="text-sm">
				{' '}
				<center>
					{' '}
					<strong>
						Use of GARDIAN and its contents is subject to our TERMS
						OF USE which incorporate this PRIVACY NOTICE by
						reference.{' '}
					</strong>
				</center>{' '}
				<center>
					{' '}
					<strong>
						{' '}
						PLEASE DO NOT ACCESS OR USE GARDIAN IF YOU DO NOT AGREE
						TO THE TERMS OF USE OR THIS PRIVACY NOTICE.{' '}
					</strong>
				</center>
			</p>
		</div>
	),
	privacy: (
		<div className="flex flex-col gap-1">
			<h4 className="pt-px font-semibold">
				COOKIES AND AUTOMATED TRACKING TOOLS{' '}
			</h4>
			<p className="text-sm">
				We use cookies to collect anonymous information and aggregate it
				to help us better understand how users interact with GARDIAN.
				Our use of cookies or other automated tracking tools or services
				does not involve the collection of any personally identifiable
				information. As an example, we use Google Analytics software to
				temporarily store and analyze a variety of information about
				your visit, however, this information cannot be used by us to
				identify you as an individual. Further information explaining
				how Google Analytics collects and uses data is available{' '}
				<a
					className="font-semibold"
					target={'_blank'}
					rel="noreferrer"
					href={
						'https://policies.google.com/technologies/partner-sites'
					}
				>
					{' '}
					here.{' '}
				</a>{' '}
			</p>
			<p className="text-sm">
				If you do not wish to have session or persistent cookies placed
				on your computer, you can disable them at any time from your web
				browser. If you opt out of cookies, you will still have access
				to all GARDIAN information and resources, but you may not be
				able to use cookie-dependent features.
			</p>
			<h4 className="pt-2 font-semibold">
				YOUR PERSONALLY IDENTIFIABLE INFORMATION
			</h4>
			<p className="text-sm">
				We request personally identifiable information in relation to
				particular functions or services associated with GARDIAN. For
				example, to subscribe for updates we require your first and last
				name, email address, and organization name and type. We respect
				user privacy; accordingly, any personally identifiable
				information you provide is protected by SSL encryption when it
				is exchanged with GARDIAN and will only be used for internal
				purposes in accordance with site-specific policies, or in ways
				to which you have explicitly consented. This information is
				password-protected, accessible only by designated staff who
				require this information to perform their duties, and is
				retained only for as long as reasonably needed for the purpose
				you have consented to or as required by law. We do not market,
				sell or transfer personally identifiable information to third
				parties unless required by law.
			</p>
			<h4 className="pt-2 font-semibold"> THIRD-PARTY CONTENT </h4>
			<p className="text-sm">
				GARDIAN allows users to access content created by third-parties.
				The administrators of GARDIAN promote responsible practices
				regarding privacy, however, no responsibility is assumed for
				third-party content that is discoverable or accessible through
				GARDIAN, including but not limited to compliance with
				institutional, legal or regulatory requirements concerning
				privacy protection or the management of personally identifiable
				information. Further information is available in our DISCLAIMER
				NOTICE.
			</p>
			<h4 className="pt-2 font-semibold">
				{' '}
				THIRD-PARTY SITES OR SERVICES{' '}
			</h4>
			<p className="text-sm">
				GARDIAN may link to third-party sites or services. Since we do
				not control them we are not responsible for their privacy
				practices and you use such sites and services entirely at your
				own risk. Further information is available in our DISCLAIMER
				NOTICE. We encourage you to review the privacy policies of
				third-party sites or services if you are sharing any personally
				identifiable information with them.
			</p>
		</div>
	),
	disclaimer: (
		<div className="flex flex-col gap-1">
			<p className="text-sm">
				Except to the extent prohibited by law no warranties or
				guarantees are provided as to the accuracy, reliability,
				ownership, non-infringement, fitness for a particular purpose,
				security or any legal or regulatory compliance status of any
				content or service that is accessible or discoverable through
				GARDIAN including through websites, platforms, tools or services
				linked to or associated with GARDIAN. In no event shall the
				administrators of GARDIAN be liable for any loss or damages of
				any nature arising in connection with access to or use of any
				such content except to the extent such liability cannot be
				excluded or limited under applicable law.
			</p>
			<p className="pt-2 text-sm">
				The administrators of GARDIAN promote a culture of open data
				with minimal to no restrictions on rights of re-use (for example
				through CC-BY licensing or CC-O public domain designation),
				however, we do not control and are not responsible for content
				created by third-parties. It is possible that content accessed
				or discoverable through GARDIAN is subject to specific terms of
				use or rights claimed by third-parties, including but not
				limited to, patent, copyright, other intellectual property
				rights, privacy protection, informed consent and/or
				biodiversity-related access and benefit-sharing rights. The user
				assumes all responsibility regarding the rights of third-parties
				as may exist in relation to content which is accessible or
				discoverable through GARDIAN.
			</p>
		</div>
	),
	terms: (
		<div className="flex flex-col gap-1">
			<h4 className="pt-2 font-semibold">YOUR PRIVACY </h4>
			<p className="text-sm">
				We respect user privacy and manage any personally identifiable
				information collected by us from you through GARDIAN in
				accordance with our PRIVACY NOTICE incorporated by reference
				herein.
			</p>
			<h4 className="pt-2 font-semibold"> OUR LIABILITY/DISCLAIMER </h4>
			<p className="text-sm">
				We strive to ensure that GARDIAN and its contents are accurate
				and current. However, in accordance with our DISCLAIMER NOTICE
				incorporated by reference herein, we do not accept liability for
				any losses or damages which may arise from using, relying on, or
				otherwise associated with GARDIAN.
			</p>
			<h4 className="pt-2 font-semibold"> THIRD-PARTY CONTENT </h4>
			<p className="text-sm">
				We are committed to responsible data practices, including
				through promotion of best practice guidelines and tools made
				available through GARDIAN. We reserve the right to modify these
				TERMS OF USE or to adopt policies imposing minimum standards,
				pledges or representations regarding third party content that is
				accessed or discoverable through GARDIAN. However, as we do not
				create or control third party content we assume no
				responsibility for third-party content accessed or discoverable
				through GARDIAN as per our PRIVACY NOTICE and DISCLAIMER NOTICE.
			</p>
			<h4 className="pt-2 font-semibold"> ACCEPTABLE USE </h4>
			<p className="text-sm">
				{' '}
				You may not use the GARDIAN or its content in any way that:
			</p>
			<ul className="list-inside list-disc text-sm">
				<li>disrupts the normal working of the website</li>
				<li>
					infringes any copyright, patent, trademark, trade secret,
					other proprietary rights, or rights of publicity or privacy
					in any way
				</li>
				<li>is illegal or otherwise unlawful</li>
				<li>
					has not been requested, including un-solicited bulk email or
					spamming
				</li>
				<li>
					is obscene, vulgar abusive, menacing or offensive or may
					cause or result in harassment, harm, distress or
					inconvenience to any persons or communities
				</li>
				<li>
					facilitates the transmission of any virus or other material
					that could potentially damage the website, and any computers
					or internet services that are connected to it
				</li>
				<li>
					unfairly impairs the reputation of GARDIAN, the
					organizations responsible for its administration, or any
					organizations or individuals that have contributed to or
					made content accessible or discoverable through GARDIAN
					(this does not include genuine user feedback regarding the
					site)
				</li>
			</ul>
			<p className="text-sm">
				You must not attempt to interfere with the website or its
				workings. In particular, you must not try to break security,
				tamper with, hack into, or otherwise disrupt any computer
				system, server, website, router, or any other device. If we
				become aware of any violation of these TERMS OF USE, we reserve
				the right to take action to stop the violation. This may include
				removing information, suspending, blocking or removing a user,
				and providing relevant information to external bodies where
				required by law or regulation.
			</p>
			<h4 className="pt-2 font-semibold">
				{' '}
				USE OF WEBSITE, IMAGES AND LOGOS ASSOCIATED WITH GARDIAN{' '}
			</h4>
			<p className="text-sm">
				You do not have to ask permission to link to pages hosted on
				GARDIAN. The images and logos associated with GARDIAN are
				subject to Copyright. Use of our logos and/or any other
				third-party logos associated with or accessed through GARDIAN is
				not permitted without prior approval from the relevant copyright
				owner. For the avoidance of doubt, the name, website, images and
				logos associated with GARDIAN may not be used for advertising,
				product endorsement, or any other purpose without our prior
				written consent.
			</p>
		</div>
	),
};
