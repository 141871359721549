import React from 'react';
import { useRoutes, Outlet, RouteObject } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MainLayout } from '../components/Layout';
import { publicRoutes } from './public';

const App = () => {
	return (
		<React.Suspense
			fallback={
				<div className="flex h-full w-full items-center justify-center">
					<ProgressSpinner />
				</div>
			}
		>
			<MainLayout>
				<Outlet />
			</MainLayout>
		</React.Suspense>
	);
};

export const AppRoutes = () => {
	// const commonRoutes = [{ path: '/', element: <Landing /> }];
	//
	// const routes = auth.user ? protectedRoutes : publicRoutes;
	//
	// const element = useRoutes([...routes, ...commonRoutes]);
	const routes: RouteObject[] = [
		{
			path: '/',
			element: <App />,
			children: publicRoutes,
		},
	];

	const element = useRoutes(routes);

	return element;
};
