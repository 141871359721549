import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Gleap from 'gleap';
import App from './App';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
	{
		path: '/*',
		element: <App />,
	},
]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

if (process.env.REACT_APP_INSTANCE !== 'local') {
	Gleap.initialize('wVlJOkahMqKLZ2lx7OCq4Cot318rAAh6');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
